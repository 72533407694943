import React, { FC } from 'react';

import './index.scss';

interface LogoProps {
  color?: string;
  onClick?: () => void;
}

const Logo: FC<LogoProps> = ({ color, onClick }) => {
  return (
    <span
      onClick={onClick}
      className="gg-logo"
      role="img"
      aria-label="dev"
      tabIndex={-1}
    >
      <svg
        height="2rem"
        width="2rem"
        viewBox="0 0 300 293"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={color}>
          <path d="m150 0c-82.825 0-150 67.1625-150 150 0 66.275 42.975 122.5 102.5875 142.3375 7.4875 1.3875 9.9125-3.2625 9.9125-7.2125v-27.925c-41.725 9.075-50.4125-17.7-50.4125-17.7-6.825-17.3375-16.6625-21.95-16.6625-21.95-13.6125-9.3125 1.0375-9.1125 1.0375-9.1125 15.0625 1.05 22.9875 15.4625 22.9875 15.4625 13.375 22.925 35.0875 16.3 43.65 12.4625 1.3375-9.6875 5.225-16.3125 9.525-20.05-33.3125-3.8125-68.3375-16.675-68.3375-74.1375 0-16.3875 5.8625-29.7625 15.45-40.2625-1.55-3.7875-6.6875-19.05 1.4625-39.7 0 0 12.6-4.025 41.2625 15.375 11.9625-3.325 24.7875-4.9875 37.5375-5.05 12.75.0625 25.5875 1.725 37.575 5.05 28.6375-19.4 41.2125-15.375 41.2125-15.375 8.1625 20.6625 3.025 35.925 1.475 39.7 9.625 10.5 15.4375 23.8875 15.4375 40.2625 0 57.6125-35.0875 70.3-68.4875 74.0125 5.375 4.65 10.2875 13.775 10.2875 27.775v41.1625c0 3.9875 2.4 8.675 10.0125 7.2 59.5625-19.8625 102.4875-76.075 102.4875-142.325 0-82.8375-67.1625-150-150-150z" />
          <path d="m105.561263 190.779925c-11.8755891-11.875589-18.0961358-27.709708-18.0961358-42.978323l61.6399628 61.074458c-15.834119-.565504-31.668238-6.220546-43.543827-18.096135zm57.681433 16.399623-74.081056-74.081056c6.2205466-27.709708 31.102733-48.6333648 60.508954-48.6333648 20.923657 0 39.019792 10.1790764 50.329877 25.4476908l-8.482563 7.351555c-9.613572-13.006597-24.882187-21.4891609-41.847314-21.4891609-22.054666 0-40.716306 14.1376059-48.067861 33.9302549l65.032988 65.032987c16.399623-5.655042 28.840716-19.792648 32.799246-36.757775h-27.144204v-11.310085h39.585297c0 29.40622-20.923657 54.288407-48.633364 60.508954z" />
        </g>
      </svg>
    </span>
  );
};

Logo.defaultProps = {
  color: '#24292f',
  onClick: () => {},
};

export default Logo;
